import React from "react"
import { RichText } from "prismic-reactjs"
import { Link } from "gatsby-plugin-intl"

import "./LexiconRow.scss"

function LexiconRow({content, letter}) {

  return (
    <div className="LexiconRow">
      <div className="LexiconRow-title">
        <div className="LexiconRow-title-index">{letter}</div>
        <div className="LexiconRow-title-line"></div>
      </div>
      <div className="LexiconRow-items">
        {content.map(x => 
          <Link to={`/marques/${x.uid}`} className="LexiconRow-item" key={x.uid}>
            {RichText.asText(x.data.name.richText)}
          </Link>
        )}
      </div>
    </div>
  )
}

export default LexiconRow
