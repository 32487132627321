import React from "react"
import { graphql } from 'gatsby'
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "@layouts/Layout"
import Metatag from "@components/metatag/Metatag"
import BannerPage from "@components/common/banner-page/BannerPage"
import Breadcrumb from "@components/common/breadcrumb/Breadcrumb"
import Lexicon from "@components/brands/lexicon/Lexicon"

function BrandsPage({ data, location, intl }) {
  const content = data?.prismicBrandspage?.data
  const brands = data?.allPrismicBrand?.edges

  if (!content) return null

  return (
    <Layout>
      <Metatag href={location.href} origin={location.origin} />
      <BannerPage
        texte={intl.formatMessage({ id: "brands" })}
        visual={content?.cover}
      />
      <Breadcrumb
        path={location.pathname}
        pageName={intl.formatMessage({ id: "brands" })}
      />
      <Lexicon brands={brands} edito={content?.edito} />
    </Layout>
  )
}

export const query = graphql`
  query brandlistpageQuery($gatsby_lang: String) {
    prismicBrandspage(lang: { eq: $gatsby_lang }) {
      data {
        edito {richText}
        cover {
          url
          alt
          thumbnails {
            mobile {
              url
            }
            tablet {
              url
            }
          }
        }
      }
    }
    allPrismicBrand(filter: {lang: {eq: $gatsby_lang}}) {
      edges {
        node {
          uid
          id
          data {
            name {
              richText
              text
            }
          }
        }
      }
    }
  }
`

export default injectIntl(BrandsPage)
