import React, { useState, useEffect } from "react"
import { RichText } from "prismic-reactjs"

import "./Lexicon.scss"
import LexiconRow from "@components/brands/lexicon/lexicon-row/LexiconRow"

function Lexicon({ brands, edito }) {

  const [brandsList, setBrandsList] = useState({})
  const [letterList, setLetterList] = useState([])

  useEffect(() => {
    let tempBrandsList = {}
    let tempLetterList = []

    brands.map(({ node }) => {
      const firstLetter = node.data.name.text.substring(0, 1).toUpperCase()
      if (tempLetterList.includes(firstLetter)) {
        tempBrandsList[firstLetter].push(node)
      } else {
        tempLetterList.push(firstLetter)
        tempBrandsList[firstLetter] = [node]
      }
    })

    for (const [key, value] of Object.entries(tempBrandsList)) {
      value.sort(orderBrand)
    }

    setLetterList(tempLetterList.sort())
    setBrandsList(tempBrandsList)
  }, [brands])

  function orderBrand(a, b) {
    const brandA = a.data.name.text.toUpperCase()
    const brandB = b.data.name.text.toUpperCase()

    let comparison = 0
    if (brandA > brandB) {
      comparison = 1
    } else if (brandA < brandB) {
      comparison = -1
    }
    return comparison
  }

  return (
    <div className="Lexicon">
      <div className="container">
        <div className="edito">{RichText.render(edito.richText)}</div>
        <div className="Lexicon-table">
          {letterList.map(x => (
            <LexiconRow content={brandsList[x]} letter={x} key={x} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Lexicon
